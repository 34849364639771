<template>
  <Toast />
  <DataTable
    ref="dv"
    responsiveLayout="scroll"
    id="entregas"
    :value="deliveries"
    dataKey="deldoc"
    :paginator="true"
    :loading="loading1"
    :filters="filters"
    :rows="25"
    editMode="cell"
    v-model:expandedRows="expandedRows"
    @rowExpand="onRowExpand"
    @cell-edit-complete="onCellEditComplete"
    class="editable-cells-table"
    @rowCollapse="onRowCollapse"
    showGridlines
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[25, 50, 75, 100, 125]"
    currentPageReportTemplate="Vie ndo {first} a {last} de {totalRecords} pedido, control de pagos "
  >
    <template #empty><b>No se encontró ningún pedido para control</b></template>
    <template #loading> Cargando control de pagos... </template>
    <Column :expander="true" :headerStyle="{ width: '2rem' }" />
    <Column
      header="No. Pedido"
      :sortable="true"
      field="deldoc"
      style="
        text-align: right;
        min-width: 1rem;
        width: 6rem;
        max-width: 12rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      "
    ></Column>
    <Column
      header="Nombre de Cliente"
      :sortable="true"
      field="delnom"
      style="min-width: 12rem"
    ></Column>
    <Column
      header="Total (Q.)"
      field="deltot"
      :sortable="true"
      style="text-align: right"
    ></Column>
    <Column
      field="delcia"
      header="Forma de Pago"
      :sortable="true"
      style="min-width: 12rem"
    >
      <template #editor="slotProps">
        <InputText v-model="slotProps.data[slotProps.data.delcia]" /> </template
    ></Column>
    <Column header="Plataforma" :sortable="true" style="min-width: 12rem">
      <template #editor="slotProps">
        <InputText v-model="slotProps.data[slotProps.data.deldoc]" />
      </template>
    </Column>
    <Column
      header="Usuario de Plataforma"
      :sortable="true"
      style="min-width: 12rem"
    >
      <template #editor="slotProps">
        <InputText v-model="slotProps.data[slotProps.data.deldoc]" /> </template
    ></Column>

    <template #expansion="slotProps"
      ><DataTable :value="slotProps.data.items">
        <br />
        <Toolbar class="p-mb-4">
          <br />
          <template v-slot:left>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <Textarea
                  id="observacion"
                  type="text"
                  rows="2"
                  :autoResize="false"
                  :maxlength="200"
                  cols="170"
                />

                <label for="observacion">Observaciones</label>
              </span>
            </div>
          </template>
        </Toolbar>
        <Column
          header="Fecha Registro "
          field="fec"
          sortable
          style="
            text-align: left;
            min-width: 1rem;
            width: 12rem;
            max-width: 12rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
        ></Column>
        <Column
          header="Observaciones "
          sortable
          field="item"
          style="text-align: left"
        ></Column
        ><Column
          header="Usuario "
          sortable
          field="username"
          style="
            text-align: left;
            min-width: 1rem;
            width: 12rem;
            max-width: 12rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
        ></Column></DataTable
    ></template>
  </DataTable>
</template>
<script>
export default {
  data() {
    return {
      filters: null,
      loading1: false,
      expandedRows: [],
      filteredCiatab: null,
      deliveries: [
        {
          deldoc: 10000,
          delnom: 'Isaias Alecio ',
          deltot: 100.99,

          items: [
            {
              item: 'Si  estás trabajando con un componente de tabla, como el de PrimeVue, para ajustar el ancho de la columna y optimizar el diseño, puedes modificar el estilo directamente en el atributo style. Aquí tienes una versión ajustada para reducir el tamaño de la columna',
              fec: '2021-12-12',
              username: 'ialecio',
            },
          ],
        },
        {
          deldoc: 2,
          delnom: 'Isaias Isaac Alecio Simon Isaias Isaac Alecio Simon',
          deltot: 200.0,

          items: [
            {
              item: 'Si  estás trabajando con un componente de tabla, como el de PrimeVue, para ajustar el ancho de la columna y optimizar el diseño, puedes modificar el estilo directamente en el atributo style. Aquí tienes una versión ajustada para reducir el tamaño de la columna',
              fec: '2021-12-12',
              username: 'ialecio',
            },
          ],
        },
      ],
    };
  },
  methods: {
    onRowExpand(event) {
      console.log(event);
    },
    onRowCollapse(event) {
      console.log(event);
    },
  },
};
</script>
